<template>
  <div class="Table department-second">
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input :value.sync="searchInput" />
        <slot name="left" />
      </div>
    </div>
    <div class="table-responsive">
      <b-table
        ref="ms-table"
        :items="items"
        :fields="fields"
        thead-class="Table__header"
        tbody-tr-class="Table__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              {{ data.label }}
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>
        <template #head(app_percent)="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              <span>{{ data.field.label.split(' ')[0] }}</span>
              <span>{{ data.field.label.split(' ')[1] }}</span>
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
        </template>
        <template #cell(logo)="data">
          <img :src="data.value" alt="image" />
        </template>
      </b-table>
    </div>
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import SortIcon from '@/components/sort-icon'
import TablePaging from '@/components/commons/common-table-paging'
import { departmentManagementService } from '@/services/department-management-service'
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash-es/get'
export default {
  name: 'DepartmentDetailTableSecond',
  components: { SortIcon, SearchInput, TablePaging },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'logo',
          label: 'ロゴ',
        },
        {
          key: 'name',
          label: 'サービス名',
          sortable: true,
        },
        {
          key: 'category_id',
          label: 'カテゴリー',
          sortable: true,
        },
        {
          key: 'staff_available',
          label: '利用中アカウント数',
          sortable: true,
        },
        {
          key: 'company_members',
          label: '従業員数',
          sortable: true,
        },
        {
          key: 'app_cost',
          label: '費用',
          sortable: true,
        },
        {
          key: 'app_percent',
          label: '費用割合 (費用/SaaS年間累計額)',
          sortable: true,
        },
      ],
      id: null,
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      searchInput: '',
      total: 0,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps_category']),
  },
  watch: {
    searchInput(newValue) {
      this.search(newValue)
    },
    perPage() {
      this.search(this.searchInput)
    },
    currentPage() {
      this.search(this.searchInput)
    },
  },
  async created() {
    await this.loadTable()
  },
  methods: {
    ...mapActions('loading', ['hideLoading']),
    async loadTable() {
      const { items, total } = await departmentManagementService.getListApp(this.$route.query.id)
      this.total = total
      this.items = this.mapItems(items)
    },
    async search(keySearch) {
      const { items, total } = await departmentManagementService.getListApp(
        this.$route.query.id,
        this.perPage,
        this.currentPage,
        keySearch
      )
      this.total = total
      this.items = this.mapItems(items)
    },
    mapItems(items) {
      return items.map((item) => ({
        logo: item.logo,
        name: item.name,
        category_id: get(
          this.apps_category.filter((data) => data.id === item.category_id)[0],
          'name',
          ''
        ),
        staff_available: item.staff_available + '/' + item.staff_used,
        company_members: item.staff_available + '/' + item.company_members,
        app_cost: item.app_cost + '円',
        app_percent: item.app_percent + '%',
      }))
    },
  },
}
</script>

<style lang="scss">
.department-second {
  .Table__header {
    th {
      &:nth-of-type(7) {
        width: 200px;
        .header--value {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
