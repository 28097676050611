<template>
  <Layout>
    <page-layout
      v-if="flag"
      page-titles="部署情報詳細"
      :bread-crumb-items="breadCrumbItems"
      :top-layout="true"
      :table-layout-other="true"
    >
      <template #top-content>
        <div class="department-detail">
          <h5 class="fw-bold">{{ pageData.code }} - {{ pageData.name }}</h5>
          <p class="font-size-12 fw-bold">
            従業員数:
            <span class="department-detail_users fw-normal">{{ pageData.members }}</span>
          </p>
          <p class="fw-bold font-size-12">
            利用中サービス数:
            <span class="department-detail_apps fw-normal">{{ pageData.apps }}</span>
          </p>
          <div class="d-flex align-items-center">
            <p class="fw-bold font-size-12">
              平均費用: <span class="department-detail_cost fw-normal">{{ cost }}円</span>
            </p>
            <div class="department-detail_button">
              <button type="button" class="btn me-2" @click="showEditDialog(pageData)">編集</button>
            </div>
          </div>
        </div>
      </template>
      <template #table-content>
        <department-detail-table-first />
      </template>
      <template #table-content-other>
        <department-detail-table-second />
      </template>
    </page-layout>
    <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
      <department-list-dialog
        :form-data.sync="formData"
        :trigger-validate="triggerValidate"
        :is-valid.sync="isValid"
      />
    </c-modal>
  </Layout>
</template>

<script>
import PageLayout from '@/components/commons/common-page-layout'
import CModal from '../../components/commons/common-modal'
import Layout from '../../layouts/main/app'
import DepartmentDetailTableFirst from '@/components/tables/department-detail-table-first'
import DepartmentDetailTableSecond from '@/components/tables/department-detail-table-second'
import DepartmentListDialog from '@/components/forms/department-list-form'
import { mapActions } from 'vuex'
import { departmentManagementService } from '@/services/department-management-service'
import pick from 'lodash-es/pick'
import { ALERT_TYPE, CURRENT_YEAR } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
export default {
  name: 'DepartmentDetail',
  components: {
    DepartmentListDialog,
    DepartmentDetailTableSecond,
    DepartmentDetailTableFirst,
    PageLayout,
    Layout,
    CModal,
  },
  data() {
    return {
      breadCrumbItems: [
        { text: '部署・従業員管理', href: '#' },
        { text: '部署', href: '/customer/department-list' },
        { text: '', href: '# ' },
      ],
      formData: '',
      cost: null,
      dialogId: 'dl-dialog',
      dialogTitle: '部署情報編集',
      triggerValidate: false,
      isValid: false,
      requiredEditKeys: ['id', 'code', 'name'],
      onSave: () => {
        return {}
      },
      pageData: {},
      flag: false,
    }
  },
  computed: {
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '更新',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onSave,
        },
      ]
    },
  },
  async created() {
    const year = this.$route.query.year || CURRENT_YEAR
    this.pageData = await departmentManagementService.getDataDetail(this.$route.query.id)
    this.breadCrumbItems[2].text = this.pageData.name
    this.cost = await departmentManagementService.getAvgCost(this.$route.query.id, year)
    this.flag = true
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    showEditDialog(rowData) {
      this.formData = { ...rowData }
      this.onSave = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      const { success, data } = await departmentManagementService.editItem(formData)
      if (!success) {
        this.showAlertError(data)
        return
      }
      this.pageData = await departmentManagementService.getDataDetail(this.$route.query.id, true)
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
    getErrorMessage(messages) {
      const result = []
      if (messages.name) {
        result.push(MESSAGES.CUSTOMER_MT.KH05)
      }
      if (messages.code) {
        result.push(MESSAGES.CUSTOMER_MT.KH04)
      }
      if (result.length === 0) {
        result.push(MESSAGES.COMMON.MSG15)
      }
      return result
    },
    showAlertError(messages) {
      const errorMessages = this.getErrorMessage(messages)
      errorMessages.forEach((mes) => {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: mes,
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.department-detail {
  padding: 10px 0 5px 22px;
  p:nth-of-type(2) {
    margin-bottom: 7px;
  }
  &_apps {
    margin-left: 18px;
    margin-bottom: 0;
  }
  &_users {
    margin-left: 66px;
  }
  &_cost {
    margin-left: 66px;
  }
  &_button {
    margin-left: auto;
    margin-bottom: 10px;
    margin-right: 16px;
    button {
      background-color: #556ee6;
      color: white;
      border-radius: 10px;
      min-width: 87px;
    }
  }
}
</style>
