<template>
  <div class="Table department-first">
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <slot name="left" />
      </div>
    </div>

    <div class="table-responsive">
      <b-table
        ref="ms-table"
        :items="items"
        :fields="fields"
        thead-class="Table__header department-first__header"
        tbody-tr-class="Table__row department-first__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              {{ data.label }}
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>
        <template #cell(name)="data">
          <router-link
            :to="{
              name: 'employee-detail',
              params: {
                id: data.item.id,
              },
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(status)="data">
          <div>
            <div :class="checkClass(data.value)">
              {{ getStatus(data.value) }}
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
    <slot name="dialog" />
  </div>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import SortIcon from '@/components/sort-icon'
import TablePaging from '@/components/commons/common-table-paging'
import { departmentManagementService } from '@/services/department-management-service'
import { mapGetters } from 'vuex'

export default {
  name: 'DepartmentDetailTableFirst',
  components: {
    SearchInput,
    SortIcon,
    TablePaging,
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'user_code',
          label: '従業員ID',
          sortable: true,
        },
        { key: 'name', label: '従業員名', sortable: true },
        {
          key: 'email',
          label: 'メールアドレス',
          sortable: true,
        },
        {
          key: 'department_name',
          label: '役職',
          sortable: true,
        },
        {
          key: 'use_app',
          label: '利用中のサービス数',
          sortable: true,
        },
        {
          key: 'status',
          label: 'ステータス',
          sortable: true,
        },
      ],
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      searchInput: '',
      id: null,
      total: 0,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['login_accepted_customer']),
  },
  watch: {
    searchInput(newValue) {
      this.search(newValue)
    },
    perPage() {
      this.search(this.searchInput)
    },
    currentPage() {
      this.search(this.searchInput)
    },
  },

  async created() {
    await this.loadTable()
  },

  methods: {
    async loadTable() {
      const { items, total } = await departmentManagementService.getListUsers(this.$route.query.id)
      this.items = items
      this.total = total
    },
    async search(keySearch) {
      const { items, total } = await departmentManagementService.getListUsers(
        this.$route.query.id,
        this.perPage,
        this.currentPage,
        keySearch
      )
      this.items = items
      this.total = total
    },
    checkClass(status) {
      return status === this.login_accepted_customer[1].id
        ? 'cell__status cell__status-active'
        : 'cell__status cell__status-inactive'
    },
    getStatus(status) {
      const statusLabel = Object.values(this.login_accepted_customer).filter(
        (item) => item.id === status
      )[0]
      return statusLabel ? statusLabel.name : ''
    },
  },
}
</script>

<style scoped></style>
